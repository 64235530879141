module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Muli"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"config/typography.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WXFXLCM","includeInDevelopment":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Confluence Conseil","short_name":"Confluence Conseil","description":"Premier cabinet de conseil à Lyon en statut Scop, nous vous accompagnons tout au long du cycle de vie de vos projets, classique ou agile. Notre société est implantée à Lyon, Nantes et Clermont Ferrand.","start_url":"/","background_color":"#EBEDF2","theme_color":"#F8F8F8","display":"standalone","icon":"src/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"3d5af69ac097d291fe9d5b87e6c18a7e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
